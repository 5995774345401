import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';
import { EmptyFileGroups } from 'state-domains/domain/drillhole';

import { buildEmptyDataFileGroupUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';

export const getEmptyFileGroups = (
    type: ACTIVITY_TYPES,
    collarId: string,
    tableViewId: string,
): Observable<EmptyFileGroups[]> => {
    const url = buildEmptyDataFileGroupUrl(collarId, tableViewId, type);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<EmptyFileGroups>) => {
            const { DataFileGroup } = response;
            const result = convertToCamel<EmptyFileGroups[]>(DataFileGroup);
            return observableOf(result);
        }),
    );
};
